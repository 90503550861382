import { Box, InputAdornment, OutlinedInput } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { INITIAL_DATA } from "../../../configs/initial-data";

import { ItemList, UserData } from "../../../types/user-data";

import "../../styles.css";
import {
  BodilyInjury,
  ClaimForYourBusiness,
  ClaimForYourBusinessFields,
  IncidentInvolves,
  MotorVehicleIncident,
  VehicleInfo,
  VehicleOwnerInfo,
  WereYouInjured,
} from "./Common";
import { useForm } from "react-hook-form";
import useCheckScreenSize from "../../../utils/checkScreenSize";
import { MobileTableView, TableView } from "./Tables";
import classNames from "classnames";
import { findtotal } from "../../../utils/common-function";
import { InfoBanner } from "../../../utils/InfoBanner";

interface Props extends UserData {
  updateFields: (fields: Partial<UserData>) => void;
  data: Partial<UserData>;
  ItemListData: ItemList[];
  setItemList: (fields: any) => void;
}
export const ClaimDetails: React.FC<Props> = ({
  updateFields,
  data,
  ItemListData,
  setItemList,
}) => {
  const {
    register,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const navigate = useNavigate();

  const [businessSubTotal, setBusinessSubTotal] = useState(0);
  const [bodilyInjurySubTotal, setBodilyInjurySubTotal] = useState(0);
  const [estimatedDamageSubTotal, setEstimatedDamageSubTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const isMobile = useCheckScreenSize({ breakpoint: 720 });

  const handleNext = () => {
    navigate("../Witnesses");
  };
  const handleBack = () => {
    navigate("../IncidentInfo");
  };
  let estRevLoss = isNaN(parseFloat(data.estimated_revenue_losses!))
    ? 0
    : parseFloat(data.estimated_revenue_losses!);
  let wagesPaid = isNaN(parseFloat(data.wages_paid_to_employee!))
    ? 0
    : parseFloat(data.wages_paid_to_employee!);
  let lostInventory = isNaN(parseFloat(data.lost_inventory!))
    ? 0
    : parseFloat(data.lost_inventory!);
  let lostWages = isNaN(parseFloat(data.bodily_injury_lost_wages!))
    ? 0
    : parseFloat(data.bodily_injury_lost_wages!);
  let medicalExpenses = isNaN(parseFloat(data.bodily_injury_medical_expenses!))
    ? 0
    : parseFloat(data.bodily_injury_medical_expenses!);
  let otherLosses = isNaN(parseFloat(data.bodily_injury_other_losses!))
    ? 0
    : parseFloat(data.bodily_injury_other_losses!);
  let estimated_damage = isNaN(parseFloat(data.estimated_damage!))
    ? 0
    : parseFloat(data.estimated_damage!);
  let itemListSum = 0;

  for (let i in ItemListData) {
    itemListSum += parseFloat(ItemListData[i].amount);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setBusinessSubTotal(estRevLoss + wagesPaid + lostInventory);
  }, [estRevLoss, wagesPaid, lostInventory, businessSubTotal]);
  useEffect(() => {
    setEstimatedDamageSubTotal(estimated_damage);
  }, [estimated_damage, estimatedDamageSubTotal]);

  useEffect(() => {
    setBodilyInjurySubTotal(lostWages + medicalExpenses + otherLosses);
  }, [lostWages, medicalExpenses, otherLosses, bodilyInjurySubTotal]);

  useEffect(() => {
    findtotal(
      data.claim_for_business,
      data.motor_vehicle_accident,
      data.were_you_injured,
      bodilyInjurySubTotal,
      businessSubTotal,
      estimatedDamageSubTotal,
      data.incident_involves,
      setTotal
    );
  }, [
    lostWages,
    medicalExpenses,
    otherLosses,
    bodilyInjurySubTotal,
    estRevLoss,
    wagesPaid,
    lostInventory,
    data.estimated_damage,
    total,
    data.claim_for_business,
    data.motor_vehicle_accident,
    data.were_you_injured,
    businessSubTotal,
    estimatedDamageSubTotal,
    data.incident_involves,
  ]);
  useEffect(() => {
    if (data.claimant_owner_of_vehicle === "Yes") {
      updateFields({ vehicle_owner_first_name: "" });
      data.vehicle_owner_first_name = "";
      data.vehicle_owner_last_name = "";
      data.vehicle_owner_address_line1 = "";
      data.vehicle_owner_address_line2 = "";
      data.vehicle_owner_city = "";
      data.vehicle_owner_state = "";
      data.vehicle_owner_phone = "";
    }
  }, [data.claimant_owner_of_vehicle]);

  useEffect(() => {
    if (data.motor_vehicle_accident === "No") {
      data.vehicle_make = "";
      data.vehicle_model = "";
      data.vehicle_year = "";
      data.estimated_damage = "";
    }
  }, [data.motor_vehicle_accident]);
  useEffect(() => {
    if (data.claim_for_business === "No") {
      data.estimated_revenue_losses = "";
      data.wages_paid_to_employee = "";
      data.lost_inventory = "";
    }
  }, [data.claim_for_business]);

  return (
    <>
      <form onSubmit={handleSubmit(handleNext)} key={1}>
        <div>
          <h2 className="subheader">Claim details</h2>

          <div style={{ marginBottom: "20px" }}>*required field </div>
          <div className="formLabel">
            <label htmlFor="describeWhatHappened">
              *Please describe what happened
            </label>
          </div>

          {isMobile === true ? (
            <textarea
              id="describeWhatHappened"
              className={classNames({
                errorTextarea: errors.describe_what_happened,
                textarea: !errors.describe_what_happened,
              })}
              {...register("describe_what_happened", {
                required: true,
                onChange: (e) =>
                  updateFields({
                    describe_what_happened: e.target.value
                      .trimStart()
                      .replace(/[~`!^;|\\":<>?]/gi, ""),
                  }),
              })}
              maxLength={1000}
              rows={10}
              cols={39}
              value={data.describe_what_happened}
            />
          ) : (
            <textarea
              id="describeWhatHappened"
              className={classNames({
                errorTextarea: errors.describe_what_happened,
                textarea: !errors.describe_what_happened,
              })}
              {...register("describe_what_happened", {
                required: true,
                onChange: (e) =>
                  updateFields({
                    describe_what_happened: e.target.value
                      .trimStart()
                      .replace(/[~`!^;|\\":<>?]/gi, ""),
                  }),
              })}
              maxLength={1000}
              rows={10}
              cols={45}
              value={data.describe_what_happened}
              aria-describedby="describeWhatHappened_error"
            />
          )}

          <div aria-live="polite">
            You have <strong>{1000 - data.describe_what_happened!.length}</strong>{" "}
            characters remaining
          </div>
          {errors.describe_what_happened ? (
            <div className="errorText" id="describeWhatHappened_error">
              Error: Detailed description required
            </div>
          ) : null}
          <ClaimForYourBusiness
            updateFields={updateFields}
            data={data}
            {...INITIAL_DATA}
            errors={errors}
            resetField={resetField}
            register={register}
            handleSubmit={handleSubmit}
            setItemList={setItemList}
          />
          {data.claim_for_business === "Yes" ? (
            <ClaimForYourBusinessFields
              updateFields={updateFields}
              data={data}
              {...INITIAL_DATA}
              {...data}
              errors={errors}
              register={register}
              handleSubmit={handleSubmit}
              setItemList={setItemList}
            />
          ) : null}
          {data.claim_for_business === "No" ? (
            <IncidentInvolves
              updateFields={updateFields}
              data={data}
              {...INITIAL_DATA}
              errors={errors}
              register={register}
              resetField={resetField}
              handleSubmit={handleSubmit}
              setItemList={setItemList}
            />
          ) : null}
          {data.claim_for_business === "Yes" ||
          data.incident_involves === "PropertyDamage" ||
          data.incident_involves === "Both" ? (
            <>
              {isMobile ? (
                <MobileTableView
                  {...INITIAL_DATA}
                  ItemListData={ItemListData}
                  setItemList={setItemList}
                />
              ) : (
                <TableView
                  {...INITIAL_DATA}
                  ItemListData={ItemListData}
                  setItemList={setItemList}
                />
              )}

              <Box sx={{ maxWidth: "21.875rem" }}>
                <div className="formLabel">
                  <label htmlFor="Subtotal">Subtotal</label>
                </div>
                <OutlinedInput
                  disabled={true}
                  id="Subtotal"
                  sx={{
                    height: "44px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4f4f4f",
                    },
                  }}
                  type="number"
                  fullWidth
                  inputProps={{ min: "0" }}
                  value={(businessSubTotal + itemListSum).toFixed(2)}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </Box>
              <br />
              <br />
            </>
          ) : null}

          {data.claim_for_business === "Yes" ||
          data.claim_for_business === "No" ? (
            <MotorVehicleIncident
              updateFields={updateFields}
              data={data}
              {...INITIAL_DATA}
              errors={errors}
              resetField={resetField}
              register={register}
              handleSubmit={handleSubmit}
              setItemList={setItemList}
            />
          ) : null}
          {data.motor_vehicle_accident === "Yes" ? (
            <VehicleInfo
              updateFields={updateFields}
              data={data}
              {...INITIAL_DATA}
              errors={errors}
              resetField={resetField}
              register={register}
              handleSubmit={handleSubmit}
              setItemList={setItemList}
            />
          ) : null}
          {data.claimant_owner_of_vehicle === "No" &&
          data.motor_vehicle_accident === "Yes" ? (
            <VehicleOwnerInfo
              updateFields={updateFields}
              data={data}
              {...INITIAL_DATA}
              errors={errors}
              register={register}
              handleSubmit={handleSubmit}
              setItemList={setItemList}
            />
          ) : null}
          {data.claim_for_business === "Yes" ? (
            <>
              <WereYouInjured
                updateFields={updateFields}
                data={data}
                {...INITIAL_DATA}
                errors={errors}
                resetField={resetField}
                register={register}
                handleSubmit={handleSubmit}
                bodilyInjurySubTotal={bodilyInjurySubTotal}
              />
            </>
          ) : null}
          {(data.were_you_injured === "Yes" &&
            data.claim_for_business === "Yes") ||
          (data.incident_involves === "BodilyInjury" &&
            data.claim_for_business === "No") ||
          (data.incident_involves === "Both" &&
            data.claim_for_business === "No") ? (
            <BodilyInjury
              updateFields={updateFields}
              data={data}
              {...INITIAL_DATA}
              errors={errors}
              register={register}
              handleSubmit={handleSubmit}
              bodilyInjurySubTotal={bodilyInjurySubTotal}
            />
          ) : null}
          <Box sx={{ maxWidth: "21.875rem" }}>
            <div className="formLabel">
              <label htmlFor="finalTotal">Total amount claimed</label>
            </div>
            <OutlinedInput
              id="finalTotal"
              disabled={true}
              type="number"
              fullWidth
              sx={{
                height: "44px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4f4f4f",
                },
              }}
              inputProps={{ min: "0" }}
              value={(total + itemListSum).toFixed(2)}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </Box>
          <br />
          <br />
          <button className="yellowButton" type="submit">
            Next
          </button>
          <div className="secondButtonDiv">
            <button onClick={handleBack} className="secondButton">
              Back
            </button>
          </div>
        </div>
      </form>
      <InfoBanner />
    </>
  );
};
