import { createTheme } from "@mui/material";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { InfoBanner } from "../../../utils/InfoBanner";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { UserData } from "../../../types/user-data";


const theme = createTheme();

theme.typography.h6 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
    fontWeight: 100,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
    fontWeight: 200,
    color: "1B1C1D",
  },
};

interface Props {
  updateFields: (fields: Partial<UserData>) => void;
  data: Partial<UserData>;
}
export const Questionnaire: React.FC<Props> = ({ updateFields, data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    register,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm<UserData>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const onSubmit = (data: any) => {
    setSubmitted(true);
    if (
      (data.is_question1 === 'Yes' &&
      data.is_question2 === 'Yes' &&
      data.is_question3 === 'Yes') ||
      data.is_question1 === 'No'
    ) {
      navigate("../ClaimantInfo");
    }
    else {
      window.location.href = 'https://www.pge.com';
    }
  };
  return (
    <div>
      <div className="description">
        We understand that you have sustained a loss for which you believe PG&E
        may be responsible. To evaluate your claim, please provide the following
        information.
      </div>
      <h2 className="subheader">Questionnaire</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formLabel">
          <label id="question1Radio">{t("InitialQuestion1")}</label>
        </div>
        <RadioGroup
          style={{
            marginTop: "5px",
            marginBottom: "10px",
          }}
          role="radiogroup"
          aria-labelledby="question1Radio"
          aria-describedby="question1_error"
        >
          <FormControlLabel
            sx={{ height: "28px" }}
            {...register("is_question1", {
              required: true,
              onChange: (e) => {
                updateFields({ is_question2: ""});
                updateFields({ is_question3: "" });
                resetField("is_question2");
                resetField("is_question3");
                updateFields({ is_question1: e.target.value });
              },
              validate: (value) => value === "No" || value === "Yes",
            })}
            id="question1_error"
            value="Yes"
            checked={data.is_question1 === "Yes"}
            control={
              <Radio id="is_question1_yes"
                sx={errors.is_question1 ? { color: "#e2231a" } : null}
              />
            }
            label="Yes"
          />
          <FormControlLabel
            {...register("is_question1", {
              required: true,
              onChange: (e) => {
                updateFields({ is_question2: ""});
                updateFields({ is_question3: ""});
                resetField("is_question2");
                resetField("is_question3");
                updateFields({ is_question1: e.target.value });
              },
              validate: (value) => value === "No" || value === "Yes",
            })}
            checked={data.is_question1 === "No"}
            value="No"
            id="question1_error"
            control={
              <Radio id="is_question1_no"
                sx={
                  errors.is_question1 && {
                    color: "#e2231a",
                  }
                }
              />
            }
            label="No"
          />
        </RadioGroup>
        {errors.is_question1 ? (
          <div className="errorText" id="question1_error">
            Error: Please select an answer
          </div>
        ) : null}
        {data.is_question1 === "Yes" ? (
          <div>
            <div className="description">                
                If you are a residential customer who has gone without power for 48 consecutive hours or greater because of severe storm conditions, you may qualify for an automatic payment under our Safety Net program. This program provides a payment of $25-100, which is paid automatically about 60 days following the storm outage. 
                <br/>
                <br/>
                For more information regarding Safety Net payments <a href='https://www.pge.com/en/outages-and-safety/outage-preparedness-and-support/general-outage-resources/outage-compensation-programs.html'>visit outage compensation</a>. <strong>This is an automatic program based on eligibility, no further action is required.</strong>
                <br/>
            </div>
                <div className="formLabel">
                  <label id="question2Radio">{t("InitialQuestion2")}</label>
                </div>
                <RadioGroup
                  style={{
                    marginTop: "5px",
                    marginBottom: "10px",
                  }}
                  role="radiogroup"
                  aria-labelledby="question2Radio"
                  aria-describedby="question2_error"
                >
                  <FormControlLabel
                    sx={{ height: "28px" }}
                    {...register("is_question2", {
                      required: true,
                      onChange: (e) => {
                        updateFields({ is_question3: ""});
                        resetField("is_question3");
                        updateFields({ is_question2: e.target.value });
                      },
                      validate: (value) => value === "No" || value === "Yes",
                    })}
                    id="question2_error"
                    value="Yes"
                    checked={data.is_question2 === "Yes"}
                    control={
                      <Radio id="is_question2_yes"
                        sx={errors.is_question2 ? { color: "#e2231a" } : null}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    {...register("is_question2", {
                      required: true,
                      onChange: (e) => {
                        updateFields({ is_question3: ""});
                        resetField("is_question3");
                        updateFields({ is_question2: e.target.value });
                      },
                      validate: (value) => value === "No" || value === "Yes",
                    })}
                    checked={data.is_question2 === "No"}
                    value="No"
                    id="question2_error"
                    control={
                      <Radio id="is_question2_no"
                        sx={
                          errors.is_question2 && {
                            color: "#e2231a",
                          }
                        }
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
                {errors.is_question2 ? (
                  <div className="errorText" id="question2_error">
                    Error: Please select an answer
                  </div>
                ) : null}
          </div>
        ) : null}
        {data.is_question2 === "Yes" ? (
                  <div>
                    <div className="description">
                      {t("InitialQuestion3Info")}
                    </div>
                    <div className="formLabel">
                      <label id="question3Radio">{t("InitialQuestion3")}</label>
                    </div>
                    <RadioGroup
                      style={{
                        marginTop: "5px",
                        marginBottom: "10px",
                      }}
                      role="radiogroup"
                      aria-labelledby="question3Radio"
                      aria-describedby="question3_error"
                    >
                      <FormControlLabel
                        sx={{ height: "28px" }}
                        {...register("is_question3", {
                          required: true,
                          onChange: (e) => {
                            updateFields({ is_question3: e.target.value });
                          },
                          validate: (value) => value === "No" || value === "Yes",
                        })}
                        id="question3_error"
                        value="Yes"
                        checked={data.is_question3 === "Yes"}
                        control={
                          <Radio id="is_question3_yes"
                            sx={errors.is_question3 ? { color: "#e2231a" } : null}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        {...register("is_question3", {
                          required: true,
                          onChange: (e) => {
                            updateFields({ is_question3: e.target.value });
                          },
                          validate: (value) => value === "No" || value === "Yes",
                        })}
                        checked={data.is_question3 === "No"}
                        value="No"
                        id="question3_error"
                        control={
                          <Radio id="is_question3_no" 
                            sx={
                              errors.is_question3 && {
                                color: "#e2231a",
                              }
                            }
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                    {errors.is_question3 ? (
                      <div className="errorText" id="question3_error">
                        Error: Please select an answer
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <br />
                <br />
                <button className="yellowButton" type="submit">
                  Next
        </button>
                <br />
                <br />
                <br />
                <br /> 
        </form>
                <InfoBanner />   
    </div>
  );
};
