export interface ClaimStep {
    labelId: string;
    displayOrder?: number;
    isWarning?: boolean;
    isError?: boolean;
    isActive?: boolean;
    key: ClaimStepKey;
  }
   
  export enum ClaimStepKey {
    Questionnaire ='Questionnaire',
    Claimant  ='Claimant Information',
    IncidentInfo ='Incident Information',
    ClaimDetails ='Claim Details',
    Witnesses ='Witnesses',
    SupportingDocuments='Supporting Documents',
    Confirmation='Confirmation'  
  }
   
  export type ClaimStepMap = {
    [k in ClaimStepKey]: ClaimStep;
  };




const ClaimsStepMap: ClaimStepMap = {
    [ClaimStepKey.Questionnaire]: {
      labelId: 'Questionnaire',
      displayOrder: 1,
      key: ClaimStepKey.Questionnaire,
    },
    [ClaimStepKey.Claimant]: {
      labelId: 'Claimant Information',
      displayOrder: 2,
      key: ClaimStepKey.Claimant,
    },
    [ClaimStepKey.IncidentInfo]: {
      labelId: 'Incident Information',
      displayOrder: 3,
      key: ClaimStepKey.IncidentInfo,
    },
    [ClaimStepKey.ClaimDetails]: {
      labelId: 'Claim Details',
      displayOrder: 4,
      key: ClaimStepKey.ClaimDetails,
    },
    [ClaimStepKey.Witnesses]: {
      labelId: 'Witnesses',
      displayOrder: 5,
      key: ClaimStepKey.Witnesses,
    },
    [ClaimStepKey.SupportingDocuments]: {
      labelId: 'Supporting Documents',
      displayOrder: 6,
      key: ClaimStepKey.SupportingDocuments,
    },
    [ClaimStepKey.Confirmation]: {
      labelId: 'Confirmation',
      displayOrder: 7,
      key: ClaimStepKey.Confirmation,
    },
  };
   
  export const getClaimsSteps = () =>
    Object.values(ClaimsStepMap).sort((stepA, stepB) => {
      const defaultValue = Number.MAX_SAFE_INTEGER;
      return (stepA.displayOrder || defaultValue) - (stepB.displayOrder || defaultValue);
    });


 
export const getActiveStepIndex = (steps: ClaimStep[]): number => {
  const activeStep = steps?.findIndex((step) => step.isActive);
  return activeStep === -1 ? 0 : activeStep;
};