import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { stateConfig } from "../../../configs/state";

import { useForm } from "react-hook-form";
import { UserData } from "../../../types/user-data";
import classNames from "classnames";
import "../../styles.css";
import { phoneFormat } from "../../../utils/common-function";
import useCheckScreenSize from "../../../utils/checkScreenSize";
import { useTranslation } from "react-i18next";
import NewTooltip from "../../../Assets/Tooltip/StyledTooltip";
import { InfoBanner } from "../../../utils/InfoBanner";

interface Props extends UserData {
  updateFields: (fields: Partial<UserData>) => void;
  data: Partial<UserData>;
}

export const Claimant: React.FC<Props> = ({ updateFields, data }) => {
  const {
    register,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm<UserData>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isMobile = useCheckScreenSize({ breakpoint: 850 });
  const [submitted, setSubmitted] = useState(false);

  if (data.is_pge_employee === "No") {
    data.lan_id = "";
  }

  const onSubmit = (data: any) => {
    setSubmitted(true);
    if (
      data.account_number!.length === 10 ||
      data.account_number!.length === 0
    ) {
      navigate("../IncidentInfo");
    }
  };
  const handleBack = () => {
    navigate("../");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <h2 className="subheader">Claimant information </h2>
      <div style={{ marginBottom: "20px" }}>*required field </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="formLabel">
            <label htmlFor="accountNumber">
              {t("AccountNumber")}
              <NewTooltip
                id="accountNumber_tooltip"
                title={
                  <div id="accountNumber_tooltip">
                    Your account number is in the upper right corner of your
                    monthly PG&E energy statement.
                  </div>
                }
              />
            </label>
          </div>{" "}
          <input
            className={classNames(
              {
                errorInput:
                  (submitted &&
                    data.account_number!.length < 10 &&
                    data.account_number!.length > 0) ||
                  data.account_number!.indexOf(" ") >= 0,
              },
              "input"
            )}
            {...register("account_number", {
              pattern: {
                value: /^\d+$/,
                message: "invalid Account Number",
              },
              onChange: (e) =>
                updateFields({
                  account_number: e.target.value.trim().replace(/\D/gi, ""),
                }),
            })}
            id="accountNumber"
            aria-describedby="accountNumber_error"
            area-aria-labelledby="accountNumber_error"
            value={data.account_number}
            maxLength={10}
          />
          {(submitted &&
            data.account_number!.length < 10 &&
            data.account_number!.length > 0) ||
          data.account_number!.indexOf(" ") >= 0 ? (
            <div className="errorText" id="accountNumber_error">
              Error: Please enter at least 10 digits
            </div>
          ) : null}
        </div>

        <div className="formLabel">
          <label htmlFor="BusinessName">{t("BusinessName")}</label>
        </div>
        <input
          className="input"
          id="BusinessName"
          maxLength={50}
          value={data.business_name}
          onChange={(e) =>
            updateFields({
              business_name: e.target.value
                .trimStart()
                .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
            })
          }
        />
        <div className="formLabel">
          <label htmlFor="firstName">*{t("FirstName")}</label>
        </div>

        <input
          className={classNames(
            {
              errorInput: errors.first_name,
            },
            "input"
          )}
          {...register("first_name", {
            required: true,
            onChange: (e) =>
              updateFields({
                first_name: e.target.value
                  .trimStart()
                  .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
              }),
          })}
          value={data.first_name}
          id="firstName"
          type="text"
          maxLength={50}
          aria-describedby="Fname_error"
        />
        {errors.first_name ? (
          <div id="Fname_error" className="errorText">
            Error: First name is required
          </div>
        ) : null}
        <div className="formLabel">
          <label htmlFor="lastName">*{t("LastName")}</label>
        </div>

        <input
          className={classNames(
            {
              errorInput: errors.last_name,
            },
            "input"
          )}
          {...register("last_name", {
            required: true,
            onChange: (e) =>
              updateFields({
                last_name: e.target.value
                  .trimStart()
                  .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
              }),
          })}
          value={data.last_name}
          id="lastName"
          maxLength={50}
          aria-describedby="lastName_error"
        />
        {errors.last_name ? (
          <div id="lastName_error" className="errorText">
            Error: Last name is required
          </div>
        ) : null}
        <div className={classNames({ row: !isMobile })}>
          <div className="column" style={{ marginRight: "2%" }}>
            <div className="formLabel">
              <label htmlFor="mailingAddress">*{t("MailingAddress")}</label>
            </div>

            <input
              id="mailingAddress"
              className={classNames({
                "input inputMail": !isMobile,
                inputMailMobile: isMobile,
                errorInputMail: errors.address_line1 && !isMobile,
                errorInputMailNoWidth: errors.address_line1 && isMobile,
              })}
              {...register("address_line1", {
                required: true,
                onChange: (e) =>
                  updateFields({
                    address_line1: e.target.value
                      .trimStart()
                      .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                  }),
              })}
              value={data.address_line1}
              maxLength={100}
              aria-describedby="MailingAddress_error"
            />
            {errors.address_line1 ? (
              <div id="MailingAddress_error" className="errorText">
                Error: Mailing address is required
              </div>
            ) : null}
          </div>
          <div className="column">
            <div className="formLabel">
              <label htmlFor="apt">{t("AptSuite")}</label>
            </div>
            <input
              id="apt"
              className={classNames({ input: isMobile, inputSmall: !isMobile })}
              onChange={(e) =>
                updateFields({
                  address_line2: e.target.value
                    .trimStart()
                    .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                })
              }
              value={data.address_line2}
            />
          </div>
        </div>

        <div className={classNames({ row: !isMobile })}>
          <div className="column" style={{ marginRight: "2%" }}>
            <div className="formLabel">
              <label htmlFor="city">*{t("City")}</label>
            </div>
            <input
              id="city"
              className={classNames(
                { errorInputCity: errors.city },
                "inputCity"
              )}
              {...register("city", {
                required: true,
                onChange: (e) =>
                  updateFields({
                    city: e.target.value
                      .trimStart()
                      .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                  }),
              })}
              value={data.city}
              maxLength={50}
              aria-describedby="City_error"
            />
            {errors.city ? (
              <div id="City_error" className="errorText">
                Error: City is required
              </div>
            ) : null}
          </div>
          <div className="column" style={{ marginRight: "2%" }}>
            <div className="formLabel">
              <label htmlFor="zipCode">*{t("ZIPCode")}</label>
            </div>
            <input
              id="zipCode"
              maxLength={5}
              className={classNames(
                { errorInputSmall: errors.zip_code },
                "inputSmall"
              )}
              {...register("zip_code", {
                required: true,
                validate: { validateNumber: (v) => v.length === 5 },
                onChange: (e) =>
                  updateFields({
                    zip_code: e.target.value.trim().replace(/[^0-9 ]/gi, ""),
                  }),
              })}
              value={data.zip_code}
              aria-describedby="Zip_Code_error"
            />
            {errors.zip_code ? (
              <div
                id="Zip_Code_error"
                className={classNames("errorText", { absolute: !isMobile })}
              >
                Error: Enter ZIP code
              </div>
            ) : null}
          </div>

          <div className="column" style={{ marginRight: "3%" }}>
            <div className="formLabel">
              <label htmlFor="state">*{t("State")}</label>
            </div>

            <select
              className="inputSmallState"
              id="state"
              defaultValue={data.state}
              value={data.state}
              onChange={(e) => updateFields({ state: e.target.value })}
            >
              {stateConfig.map((state, index) => (
                <option key={index} value={state.key}>
                  {" "}
                  {state.key}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="formLabel">
          <label htmlFor="primaryPhone">*{t("PrimaryPhone")}</label>
        </div>
        <input
          id="primaryPhone"
          maxLength={14}
          value={phoneFormat(data.primary_phone)}
          className={classNames({ errorInput: errors.primary_phone }, "input")}
          {...register("primary_phone", {
            required: true,
            pattern: {
              value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
              message: "Error: Please enter a valid phone number",
            },
            onChange: (e) => {
              updateFields({ primary_phone: e.target.value });
            },
          })}
          aria-describedby="primary_Phone_error"
        />
        {errors.primary_phone ? (
          <div className="errorText" id="primary_Phone_error">
            Error: You must enter a 10-digit phone number
          </div>
        ) : null}

        <div className="formLabel">
          <label htmlFor="AlternatePhone">{t("AlternatePhone")}</label>
        </div>
        <input
          id="AlternatePhone"
          maxLength={14}
          value={phoneFormat(data.alternate_phone)}
          className={classNames(
            {
              errorInput: errors.alternate_phone,
            },
            "input"
          )}
          {...register("alternate_phone", {
            pattern: {
              value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
              message: "Error: Please enter a valid phone number",
            },
            onChange: (e) => {
              updateFields({ alternate_phone: e.target.value });
            },
          })}
        />
        {errors.alternate_phone ? (
          <div className="errorText" id="primary_Phone_error">
            Error: You must enter a 10-digit phone number
          </div>
        ) : null}
        <div className="formLabel">
          <label htmlFor="Email">*{t("Email")}</label>
        </div>
        <input
          id="Email"
          className={classNames({ errorInput: errors.email }, "input")}
          value={data.email}
          maxLength={50}
          {...register("email", {
            required: true,
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Error: ",
            },
            onChange: (e) => updateFields({ email: e.target.value }),
          })}
          aria-describedby="Email_id_error"
        />
        {errors.email ? (
          <div className="errorText" id="Email_id_error">
            Error: Please enter a valid email address
          </div>
        ) : null}
        <div className="formLabel">
          <label htmlFor="confirmEmail">*{t("ConfirmEmail")}</label>
        </div>
        <input
          id="confirmEmail"
          className={classNames({ errorInput: errors.confirm_email }, "input")}
          value={data.confirm_email}
          maxLength={50}
          {...register("confirm_email", {
            required: true,
            onChange: (e) => {
              updateFields({ confirm_email: e.target.value });
            },
            validate: (value) => value === data.email,
          })}
          aria-describedby="confirmEmail_error"
        />
        {errors.confirm_email ? (
          <div className="errorText" id="confirmEmail_error">
            Error: Email addresses must match
          </div>
        ) : null}
        <div className="formLabel">
          <label id="pgeEmployeeRadio">*{t("AreYouPG&EEmployee")}</label>
        </div>

        <RadioGroup
          style={{
            marginTop: "5px",
            marginBottom: "10px",
          }}
          role="radiogroup"
          aria-labelledby="pgeEmployeeRadio"
          aria-describedby="pgeEmployee_error"
        >
          <FormControlLabel
            sx={{ height: "28px" }}
            {...register("is_pge_employee", {
              required: true,
              onChange: (e) => {
                resetField("lan_id");
                updateFields({ is_pge_employee: e.target.value });
              },
              validate: (value) => value === "No" || value === "Yes",
            })}
            id="pgeEmployee_error"
            value="Yes"
            checked={data.is_pge_employee === "Yes"}
            control={
              <Radio
                sx={errors.is_pge_employee ? { color: "#e2231a" } : null}
              />
            }
            label="Yes"
          />
          <FormControlLabel
            {...register("is_pge_employee", {
              required: true,
              onChange: (e) => {
                resetField("lan_id");
                updateFields({ is_pge_employee: e.target.value });
              },
              validate: (value) => value === "No" || value === "Yes",
            })}
            checked={data.is_pge_employee === "No"}
            value="No"
            id="pgeEmployee_error"
            control={
              <Radio
                sx={
                  errors.is_pge_employee && {
                    color: "#e2231a",
                  }
                }
              />
            }
            label="No"
          />
        </RadioGroup>
        {errors.is_pge_employee ? (
          <div className="errorText" id="pgeEmployee_error">
            Error: Are you currently employed by PG&E? If not, select No above
          </div>
        ) : null}
        {data.is_pge_employee === "Yes" ? (
          <div>
            <div className="formLabel">
              <label htmlFor="lanId">*{t("LANID")}</label>
            </div>

            <input
              className={classNames(
                {
                  errorInput:
                    errors.lan_id ||
                    (data.lan_id!.length < 4 && data.lan_id!.length > 0) ||
                    data.lan_id!.indexOf(" ") >= 0,
                },
                "input"
              )}
              value={data.lan_id}
              {...register("lan_id", {
                required: true,
                onChange: (e) =>
                  updateFields({
                    lan_id: e.target.value.replace(/[^0-9a-zA-Z']/gi, ""),
                  }),
              })}
              id="lanId"
              maxLength={4}
              minLength={4}
              aria-describedby="lanId_error"
            />
            {errors.lan_id ? (
              <div className="errorText" id="lanId_error">
                Error: LAN ID is required
              </div>
            ) : null}
            {(data.lan_id!.length < 4 && data.lan_id!.length > 0) ||
            data.lan_id!.indexOf(" ") >= 0 ? (
              <div className="errorText" id="lanId_error">
                Error: Please enter valid LAN ID
              </div>
            ) : null}
          </div>
        ) : null}
        <br />
        <br />
        <button className="yellowButton" type="submit">
          Next
        </button>
        <div className="secondButtonDiv">
            <button onClick={handleBack} className="secondButton">
              Back
            </button>
        </div>
        <br />
        <br />
        <br />
        <br />
      </form>
      <InfoBanner />
    </div>
  );
};
