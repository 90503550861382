import { Alert, Checkbox, FormControlLabel, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ItemList, UserData, WitnessList } from "../../../types/user-data";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AlertIcon } from "../../../Assets/Alert.svg";
import { ReactComponent as UploadIcon } from "../../../Assets/Upload.svg";

import { ReactComponent as CloseFileIcon } from "../../../Assets/CloseFile.svg";
import { ReactComponent as SuccessIcon } from "../../../Assets/Success.svg";
import { ReactComponent as CheckboxIcon } from "../../../Assets/Checkbox.svg";
import { v4 as uuid } from "uuid";
import classNames from "classnames";
import useCheckScreenSize from "../../../utils/checkScreenSize";
import "./upload.css";
import { DeleteFile } from "./DeleteFile";
import CircularProgress from "@mui/material/CircularProgress";
import { InfoBanner } from "../../../utils/InfoBanner";
import { INITIAL_DATA } from "../../../configs/initial-data";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/material";

interface Props extends UserData {
  setClaimId: (value: string) => void;
  setMultipleImages: (value: any) => void;
  multipleImages: any;
  data: Partial<UserData>;
  ItemListData: ItemList[];
  witnessListData: WitnessList[];
  updateFields: (fields: Partial<UserData>) => void;
  setWitnessListData: (fields: any) => void;
  setItemList: (fields: any) => void;
}

const AcceptedFileType = [
  "txt",
  "jpg",
  "png",
  "doc",
  "pdf",
  "docx",
  "xls",
  "xlsx",
  "heic",
  "jpeg"
];

export const SupportingDocuments: React.FC<Props> = ({
  data,
  ItemListData,
  witnessListData,
  setClaimId,
  setMultipleImages,
  multipleImages,
  updateFields,
  setWitnessListData,
  setItemList,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    shouldFocusError: false,
  });
  const navigate = useNavigate();

  const isMobile = useCheckScreenSize({ breakpoint: 850 });
  const [singleFile, setSingleFile] = useState<[]>([]);
  const [submitted, setSubmitted] = useState(false);

  const [fileSizeExceed, setFileSizeExceed] = useState(false);
  const [fileAlreadyExist, setFileAlreadyExist] = useState(false);
  const [fileFormatSupport, setFileFormatSupport] = useState(false);
  const [agree, setAgree] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [systemError, setSystemError] = useState(false);
  const [processingError, setProcessingError] = useState(false);
  const UUID = uuid().toString().replace(/-/g, "");

  const [filename, setFilename] = useState("");
  const [openAgreement, setOpenAgreement] = React.useState(false);
  const [openSubmit, setOpenSubmit] = React.useState(false);
  const recaptchaRef = React.createRef() as any;

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  function onCaptchaChange(value: any) {
    setToken(value);
  }
  const handleAgree = (e: any) => {
    setAgree(e.target.checked);
    if (e.target.checked) {
      data.agreement_signed = "Yes";
      if ((document.getElementById("fileupload") as HTMLInputElement).value !== ""){        
            handleClickOpenAgreement();       
      }
    } else {
      data.agreement_signed = "No";
    }
  };

  const handleBack = () => {
    data.agreement_signed = "No";
    navigate("../Witnesses");
  };
  const handleFileDelete = (index: any) => {
    const tempFiles = [...multipleImages];
    tempFiles.splice(index, 1);
    setMultipleImages(tempFiles);
  };
  const uploadMultipleFiles = (singleFile: any) => {
    const fileExtension = singleFile?.name?.split(".").at(-1);
    setFilename("");
    const findDuplicate = multipleImages.map((image: any, index: number) => {
      if (image.name === singleFile.name) {
        setFileSizeExceed(false);
        setFileAlreadyExist(true);
        setFileFormatSupport(false);

        setSingleFile([]);
        (document.getElementById("fileupload") as HTMLInputElement).value = "";
        return true;
      }
      return false;
    });

    if (!findDuplicate.includes(true)) {
      if (!AcceptedFileType.includes(fileExtension.toLowerCase())) {
        setFileSizeExceed(false);
        setFileAlreadyExist(false);
        setFileFormatSupport(true);

        setSingleFile([]);
        (document.getElementById("fileupload") as HTMLInputElement).value = "";
        return false;
      } else if (singleFile.size > 10e6) {
        setFileSizeExceed(false);
        setFileAlreadyExist(false);

        setFileSizeExceed(true);
        setFileFormatSupport(false);
        setSingleFile([]);
        (document.getElementById("fileupload") as HTMLInputElement).value = "";
        return false;
      } else {
        if (singleFile) {
          setMultipleImages((prevImages: string | any[]) =>
            prevImages.concat(singleFile)
          );

          setFileSizeExceed(false);
          setFileAlreadyExist(false);
          setFileFormatSupport(false);
        }
        setSingleFile([]);
        (document.getElementById("fileupload") as HTMLInputElement).value = "";
      }
    }
  };

  async function formSubmission() {
    //deleting incident_location and claimant_owner_of_vehicle from the form data since these are not required
    delete data["incident_location"];
    delete data["claimant_owner_of_vehicle"];
    delete data["is_question1"];
    delete data["is_question2"];
    delete data["is_question3"];
    const requestBody = {
      request_id: UUID,
      total_attachments: multipleImages.length,
      claim: data,
      item_list: ItemListData,
      witness_list: witnessListData,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_FORM_POST_URL as string,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (response.status === 200) {
        updateFields(INITIAL_DATA);
        setWitnessListData([]);
        setItemList([]);
      }

      if (
        response.status === 500 ||
        response.status === 502 ||
        response.status === 403
      ) {
        setSystemError(true);
        setLoading(false);
      }
      if (response.status === 400) {
        setProcessingError(true);
        setLoading(false);
      }
      let res = await response.json();

      if (res.claim_id) {
        setClaimId(res.claim_id);
      }
      return res;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const documentArray: any[] = [];

  async function UploadApiCall(formData: any, fileName: any) {
    documentArray.push(fileName);
    const response = await fetch(
      process.env.REACT_APP_UPLOAD_S3_URL as string,
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.status !== 204) {
      let fileRetryCount = documentArray.filter((arr: any) => arr === fileName);
      setUploadError(true);

      if (fileRetryCount.length < 3) {
        UploadApiCall(formData, fileName);
      }
    }
  }

  const asyncGetCallUpload = async (response: any) => {
    multipleImages.forEach((image: any, index: number) => {
      const i = index + 1;
      const keyvalue = `uploads/${
        response.claim_id
      }_${UUID}_${i}_${image.name.toLowerCase()}`;

      let formData = new FormData();
      formData.append("acl", "private");
      formData.append("key", keyvalue);
      formData.append("x-amz-algorithm", response.fields["x-amz-algorithm"]);
      formData.append("x-amz-credential", response.fields["x-amz-credential"]);
      formData.append("x-amz-date", response.fields["x-amz-date"]);
      formData.append(
        "x-amz-security-token",
        response.fields["x-amz-security-token"]
      );
      formData.append("policy", response.fields.policy);
      formData.append("x-amz-signature", response.fields["x-amz-signature"]);
      formData.append("file", multipleImages[index]);
      if (response.fields) {
        UploadApiCall(formData, image.name);
      }
    });
  }; 
  const handleSubmitClaims = () => {
    setSubmitted(true);  
    
    async function callFormApi() {
      const formSubmissionResponse = await formSubmission();

      if (
        formSubmissionResponse &&
        formSubmissionResponse.statusCode === 200 &&
        !formSubmissionResponse.error
      ) {
        setLoading(false);
        asyncGetCallUpload(formSubmissionResponse);
        if (!uploadError) {
          navigate("../Confirmation");
        }
      }
    }

    if (
      !errors.length &&
      token.length > 0 &&
      token !== "null" &&
      data.agreement_signed === "Yes"
    ) {
      callFormApi();
      setLoading(true);
    }    
  };

  const handleClickOpenAgreement = () => {
    setOpenAgreement(true);
  };
 
  const handleCloseAgreement = () => {
    setOpenAgreement(false);
  };

  const handleForgotUpload = () => {  
    setOpenAgreement(false);
  };
  
  const handleSubmitClaimsDialog = () => {
    if (!token) {
      setToken("null");  
    }
    if(data.agreement_signed != "Yes"){
      document.getElementById('Agreement')?.focus();
      return false;
    }
    if (token=="null") {     
      if(data.agreement_signed == "Yes") {
        let x:any=recaptchaRef.current; 
        if(x){
          x.captcha.tabIndex=0;
          x.captcha.focus();
        }else{
          let y:any=document.getElementById('recaptchaRefDiv');
          y.tabindex=0;
          y.focus();
        }
      }
      return false;
    } 
    if(multipleImages.length==0 ){
      setOpenSubmit(true);
      return false;
    }  
    handleSubmitClaims();
  };

  const handleSubmitAnywayClaims = () => {    
    setOpenSubmit(false);
    handleSubmitClaims();
  };

  const handleDontSubmit = () => {    
    setOpenSubmit(false);
    return false;
  };
  
  return (
    <div>
      <Stack sx={{ width: "100%" }} spacing={2}>
        {uploadError && (
          <Alert
            icon={
              <div>
                <AlertIcon />
              </div>
            }
            sx={{
              background: "#F7D8D8",
              color: "black",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.07)",
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          >
            Error: We have encountered a problem processing your upload
            document. Please re-upload the document.
          </Alert>
        )}
        {systemError && (
          <Alert
            icon={
              <div>
                <AlertIcon />
              </div>
            }
            sx={{
              background: "#F7D8D8",
              color: "black",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.07)",
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          >
            Error: We have encountered a problem processing your submit form.
            Please re-submit the form.
          </Alert>
        )}
        {processingError && (
          <Alert
            icon={
              <div>
                <AlertIcon />
              </div>
            }
            sx={{
              background: "#F7D8D8",
              color: "black",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.07)",
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          >
            Error: We have encountered a problem processing your request. Please
            verify that you have entered all the required information and
            re-submit.
          </Alert>
        )}
        {fileFormatSupport ? (
          <Alert
            icon={
              <div>
                <AlertIcon />
              </div>
            }
            sx={{
              background: "#F7D8D8",
              color: "black",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.07)",
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          >
            <div style={{ marginTop: "3px" }}>
              Error: Allowed file types
              .pdf,.txt,.doc,.docx,.xls,.xlsx,.jpg,.png,.heic,.jpeg
            </div>
          </Alert>
        ) : null}
        {fileSizeExceed ? (
          <Alert
            icon={
              <div>
                <AlertIcon />
              </div>
            }
            sx={{
              background: "#F7D8D8",
              color: "black",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.07)",
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          >
            <div style={{ marginTop: "3px" }}>
              Error: File size must be less than 10MB
            </div>
          </Alert>
        ) : null}
        {fileAlreadyExist ? (
          <Alert
            icon={
              <div>
                <AlertIcon />
              </div>
            }
            sx={{
              background: "#F7D8D8",
              color: "black",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.07)",
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "15px",
              alignContent: "center",
            }}
          >
            <div style={{ marginTop: "3px" }}>
              Error: A file with the same name has already been uploaded
            </div>
          </Alert>
        ) : null}
      </Stack>
      <h2 className="subheader">Add supporting documents</h2>
      <div style={{ marginBottom: "20px" }}>*required field </div>
      <div className="description">
        To process your claims, we will need supporting documents for each item
        claimed.
      </div>
      <div className="description">
        <ul>
          <li style={{ fontWeight: "700" }}>
            <strong>Add up to 5 documents (e.g. receipts and estimates)</strong>
          </li>
        </ul>
      </div>
      <div className="description">
        <ul>
          <li style={{ fontWeight: "700" }}>
            {" "}
            <strong> Files must be less than 10MB each</strong>
          </li>
        </ul>
      </div>
      <div className="description">
        <ul>
          <li style={{ fontWeight: "700" }}>
            <strong>
              File types accepted: .pdf,.txt,.doc,.docx,.xls,.xlsx,.jpg,.png,.heic or
              .jpeg.
            </strong>
          </li>
        </ul>
      </div>
      <div className="description">
        <label style={{ fontWeight: "700" }}>NOTE: </label> Your claims
        representative will contact you within the next 3 business days. They
        can help if you have more than 5 documents or have additional
        information.
      </div>
      <div className="label" style={{ marginBottom: "5px" }}>
        <label>Attach File</label>
      </div>
      <div className="description">
        <ol>
          <li>
            Click the upload icon [<UploadIcon />] and select the file(s) from
            your desktop.
          </li>

          <li>Click the Upload button.</li>
          <li>The files will appear as a list below.</li>
        </ol>
      </div>

      <br />
      <div >
        <div style={{ marginRight: "15px" }}>
          <span id="file-selected" className="fileName">
            {filename}
          </span>

          <div className="input">
            <input
              id="fileupload"
              type="file"
              className="upload"
              {...register("file", {
                onChange: (e: any) => {
                  setSingleFile(e.target?.files[0]);
                  setFilename(e.target?.files[0].name);
                },
              })}
              disabled={multipleImages.length === 5 ? true : false}
            />
            <label className="imageUploader" htmlFor="fileupload">
              <div
                style={{ marginLeft: "-30px" }}
                className="secondButton"
                aria-label="upload"
              >
                {filename &&
                multipleImages.length > 0 &&
                multipleImages?.find(
                  (image: any) => image.name !== filename
                ) ? (
                  <SuccessIcon />
                ) : (
                  <UploadIcon />
                )}
              </div>
              <button
                onClick={() => setFilename("")}
                className="closeFile secondButton"
                aria-label="clearFile"
              >
                <CloseFileIcon />
              </button>
            </label>
          </div>
          <div style={{ marginTop: "10px" }}>Max. file size: 10MB</div>
        </div>     
        <br />
        
        <button
          className={classNames(
            multipleImages.length === 5 ? "disableButton" : "blueButton"
          )}
          onClick={() => {
            if (singleFile) {
              uploadMultipleFiles(singleFile);
            }
          }}
          disabled={multipleImages.length === 5 || singleFile.length === 0}
          title="upload"
        >
          Upload{" "}
          <UploadIcon title="An arrow points up to indicate a file upload" />
        </button>
      </div>
      <br />
      <br />
      {multipleImages.length > 0 && <div className="label">Files attached</div>}
      <br />
      {multipleImages.length > 0
        ? multipleImages.map((image: any, index: number) => {
            return (
              <div
                className="row"
                key={image.name}
                style={{ marginBottom: "8px" }}
              >
                <label>{image.name}</label>
                <div className="deleteFile">
                  <DeleteFile
                    index={index}
                    handleDelete={handleFileDelete}
                    {...multipleImages}
                  />
                </div>
              </div>
            );
          })
        : null}
      <br />
      <form onSubmit={handleSubmit(handleSubmitClaims)}>
        <div className="label">
          <label htmlFor="Agreement">*Agreement</label>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div>
            <FormControlLabel
              id="Agreement"
              aria-labelledby="Agreement agree_error"
              control={
                <div style={{ alignSelf: "start", marginTop: "-6px" }}>
                  <Checkbox                    
                    sx={errors.check && { color: "#e2231a" }}
                    checkedIcon={<CheckboxIcon className="checkSize" />}
                  />
                </div>
              }
              checked={agree}
              {...register("check", {
                validate: (value) => value === true,
              })}
              onChange={(e) => handleAgree(e)}
              label="*By submitting this form I understand that Pacific Gas and Electric Company will review all documentation in support of this claim. I certify that the foregoing information entered is true and correct."
            />
            </div>
            <Dialog
            open={openAgreement}
            onClose={handleCloseAgreement}
            aria-labelledby="aggreement-alert-dialog-title"
            aria-describedby="aggreement-alert-dialog-description"
            >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                m: "auto",
                width: "fit-content",
              }}
              className={classNames(!isMobile && "popup")}
            >
              <DialogTitle id="aggreement-alert-dialog-title">
                <h2 className="subheader">File not uploaded</h2>
              </DialogTitle>
              <DialogContent>
                <div id="aggreement-alert-dialog-description">You have not uploaded the selected file, Please click the [Upload ^] button</div>
                <br />
                <br />
                <div style={{ marginTop: "10px",marginBottom: "50px" }}>
                  <button
                    className={classNames(
                      "yellowButton",
                      isMobile && "popupInputMobileItem"
                    )}
                    onClick={handleForgotUpload}
                  >
                    Ok
                  </button>
                </div> 
                <br />
              </DialogContent>
            </Box>
          </Dialog>          
        </div>
        {data.agreement_signed !== "Yes" && submitted && (
          <div className="errorText" id="agree_error">
            Error: Please read and agree to the terms.
          </div>
        )}

        <br />
        <div className="formLabel">*Security</div>
        <div className="inputDescription">
          Please verify that you are not a robot.
        </div>
        <label htmlFor="recaptcha" className="visuallyhidden">
          recaptcha
        </label>
        <div aria-live="polite" id='recaptchaRefDiv'>
          <ReCAPTCHA
            aria-required="true"
            id="recaptcha"
            aria-labelledby="recaptcha reCAPTCHA_error"
            aria-describedby="reCAPTCHA_error"
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
            onChange={onCaptchaChange}
            theme="dark"
            ref={recaptchaRef}
          />
        </div>

        {token === "null" && (
          <div className="errorText" id="reCAPTCHA_error">
            Error: Verify that you are not a robot
          </div>
        )}
        <br />
        <br />
        <button
          className={classNames({ onLoad: loading }, "yellowLoadingButton")}
          type="submit"
          onClick={handleSubmitClaimsDialog}
          disabled={loading}
        >
          Submit {loading && <CircularProgress color="inherit" size={15} />}
        </button>
        <Dialog
          open={openSubmit}
          onClose={handleDontSubmit}
          aria-labelledby="submit-alert-dialog-title"
          aria-describedby="submit-alert-dialog-description"
          disableEnforceFocus
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
            className={classNames(!isMobile && "popup")}
          >
            <DialogTitle id="submit-alert-dialog-title">
              <h2 className="subheader">Submit without attachment</h2>
            </DialogTitle>
            <DialogContent>
              <div id="submit-alert-dialog-description">Do you want to submit this Claim without uploading documents?</div>
              <br />
              <br />
              <div style={{ marginTop: "10px" }}>
                <button
                  type="submit" 
                  className={classNames(
                    "yellowButton",
                    isMobile && "popupInputMobileItem"
                  )}
                  onClick={handleSubmitAnywayClaims}
                >
                  Submit Anyway
                </button>
              </div>

              <div className="secondButtonDiv">
                <button className="secondButton" onClick={handleDontSubmit}>
                Don’t Submit
                </button>
              </div>
              <br />
            </DialogContent>
          </Box>
        </Dialog>
        <div className="secondButtonDiv">
          <button onClick={handleBack} className="secondButton">
            Back
          </button>
        </div>
      </form>
      <InfoBanner />
    </div>
  );
};
    
