import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function InfoBannerSvg() {
  return (
    <SvgIcon viewBox = '0 0 24 23' fill= 'none' aria-hidden='false' width="24" height="23" xmlns= "http://www.w3.org/2000/svg" aria-labelledby='infoSign'>
            <g clip-path="url(#clip0_904_10695)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.21481C7.02944 3.21481 3 6.94677 3 11.5504C3 16.154 7.02944 19.8859 12 19.8859C16.9706 19.8859 21 16.154 21 11.5504C21 6.94677 16.9706 3.21481 12 3.21481ZM1 11.5504C1 5.92374 5.92487 1.36246 12 1.36246C18.0751 1.36246 23 5.92374 23 11.5504C23 17.177 18.0751 21.7383 12 21.7383C5.92487 21.7383 1 17.177 1 11.5504Z" fill="#4F4F4F"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10.6242C12.5523 10.6242 13 11.0389 13 11.5504V15.2551C13 15.7666 12.5523 16.1813 12 16.1813C11.4477 16.1813 11 15.7666 11 15.2551V11.5504C11 11.0389 11.4477 10.6242 12 10.6242Z" fill="#4F4F4F"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11 7.84567C11 7.33416 11.4477 6.91949 12 6.91949H12.01C12.5623 6.91949 13.01 7.33416 13.01 7.84567C13.01 8.35718 12.5623 8.77184 12.01 8.77184H12C11.4477 8.77184 11 8.35718 11 7.84567Z" fill="#4F4F4F"/>
            </g>
            <defs>
            <clipPath id="clip0_904_10695">
            <rect width="24" height="22.2282" fill="white" transform="translate(0 0.436279)"/>
            </clipPath>
            </defs>
            <title id="infoSign">Information</title>      
    </SvgIcon>
  );
}