export const en = {
  'AppName': 'PG&E Claims',
  'AccountNumber': 'PG&E account number',
  "BusinessName": "Business name",
  "FirstName":"First name",
  "LastName":"Last name",
  "MailingAddress":"Mailing address",
  "AptSuite":"Apt/Suite",
  "City":"City",
  "ZIPCode":"ZIP code",
  "State":"State",
  "PrimaryPhone":"Primary phone number",
  "AlternatePhone":"Alternate phone number",
  "Email":"Email address",
  "ConfirmEmail":"Confirm email address",
  "AreYouPG&EEmployee":"Are you a PG&E employee?",
  "LANID":"LAN ID",
  "InitialQuestion1":"Is your claim related to a storm or an electric outage?",  
  "InitialQuestion2":"Do you still want to file a claim (YES/NO)",
  "InitialQuestion3Info":"Generally speaking, PG&E is not responsible for damages that we do not cause or that are the result of forces beyond our control. For example, in most instances, we are not responsible in the following situations: Power outages, voltage fluctuations or property damage caused by weather-related conditions, such as lightning, floods, storms, vegetation (tree/branches), heat or winds, earthquakes, or other acts of nature.",
  "InitialQuestion3":"Do you still want to file a claim (YES/NO)",
};