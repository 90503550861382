import { createTheme } from "@mui/material";

import React, { useEffect } from "react";

const theme = createTheme();

theme.typography.h6 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
    fontWeight: 100,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
    fontWeight: 200,
    color: "1B1C1D",
  },
};

interface Props {
  claimId: string;
}
export const Confirmation: React.FC<Props> = ({ claimId }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <h2 className="subheader">Your claim submission has been received.</h2>

      <div className="formLabel">
        <strong>Your claim number is {claimId}.</strong>
      </div>

      <div className="description">
        <br/>It is Pacific Gas and Electric Company&#39;s policy to promptly and fairly respond to customers&#39; claims. All claims are evaluated on a case-by-case basis. It is our goal to resolve your claim within 30 days of receipt. However, some claims do take longer to investigate and resolve. By submitting supporting documentation for your loss immediately the handling of your claim will be expedited. When our investigation is complete, we will either call you or send you a letter explaining what we found and the basis for our decision.<br/>
      </div>

      <h3 className="formLabel">What to expect</h3>

      <div className="description">
                      If you have not provided supporting documents OR have additional documents to submit (e.g.,
                      Receipt, or Estimates for items claimed) please submit your supporting documentation to: <a
                      href={"mailto:claimdocs@pge.com?subject=" + claimId}
                      style={{ color: "#07679B" }}
                      target="_blank"
                      rel="noreferrer">
                      claimsdocs@pge.com?subject={claimId}
                    </a>.                                  
      </div>
      
      <div className="description">             
        If you have any questions, you can contact our help desk at 415-973-4548.           
      </div>
      
      <div className="description">Sincerely,</div>
      <div className="description">PG&E Claims Team</div>
    </div>
  );
};
